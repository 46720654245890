import {createApp} from 'vue'
import App from './App.vue'
import axios from 'axios'

import {
    create,
    NButton,
    NGrid,
    NGi,
    NSpace,
    NIcon,
    NTooltip,
    NModal,
    NCard,
    NEllipsis,
    NLoadingBarProvider
} from 'naive-ui'

const naive = create({
    components: [NButton, NGrid, NGi, NSpace, NIcon, NTooltip, NModal, NCard, NEllipsis, NLoadingBarProvider]
})


const app = createApp(App);

app.config.globalProperties.$axios = axios;

app.use(naive);
app.mount('#app')
