<template>
  <div>
    <n-loading-bar-provider>
      <Main/>
    </n-loading-bar-provider>
    <Foot/>
  </div>
</template>

<script>

import Main from "./components/Main.vue"
import Foot from "./components/Foot.vue"

export default {
  name: 'App',
  components: {
    Main, Foot
  }
}
</script>

<style>
* {
  margin: 0 auto;
}

@media screen and (max-width: 768px) {
  html {
    font-size: 12px;
  }
}

@media screen and (min-width: 768px) {
  html {
    font-size: 14px;
  }
}

@media screen and (min-width: 992px) {
  html {
    font-size: 16px;
  }
}

@media screen and (min-width: 1200px) {
  html {
    font-size: 18px;
  }
}

.err {
  color: #F56C6C;
}

.warn {
  color: #E6A23C;
}

.success {
  color: #67C23A
}

.default {
  color: #50a1ff
}

</style>
