<template>
  <div id="main">
    <video autoplay loop muted class="fullScreenVideo" src="../assets/1.mp4" type="video/mp4"></video>
    <div class="g-bg">
      <div class="g-polygon-1 color-change-1x"></div>
      <div class="g-polygon-2 color-change-2x"></div>
      <div class="g-polygon-3 color-change-3x"></div>
    </div>
    <div id="main-container">
      <img id="headIcon" src="../assets/head.jpg">
      <div id="desc">
        <n-ellipsis id="desc-content" class="desc-content" expand-trigger="click" line-clamp="1" :tooltip="false">
          {{ desc }}
        </n-ellipsis>
      </div>
      <div id="link-me">
        <n-tooltip trigger="hover" placement="left">
          <template #trigger>
            <n-button strong secondary circle type="primary" @click="weChat">
              <template #icon>
                <n-icon>
                  <WeChat/>
                </n-icon>
              </template>
            </n-button>
          </template>
          微信联系
        </n-tooltip>
        <n-tooltip trigger="hover" placement="right">
          <template #trigger>
            <n-button strong secondary circle type="primary" @click="qq">
              <template #icon>
                <n-icon>
                  <QQ/>
                </n-icon>
              </template>
            </n-button>
          </template>
          QQ联系
        </n-tooltip>
        <n-modal v-model:show="showModal">
          <n-card style="width: 29.25rem;border-radius: 25px;" :bordered="false" size="small" hoverable role="dialog"
                  aria-modal="true">
            <img class='wechatpng' src="../assets/wechat.png" alt=""/>
            <br/>
            <div style='width: 100%; text-align: center'>
              <span style="user-select: none" class='warn'>使用手机微信App扫码</span>
            </div>
          </n-card>
        </n-modal>
      </div>
      <div id="skip-link">

      </div>
    </div>
  </div>
</template>

<script>

import {LogoWechat as WeChat} from '@vicons/ionicons5'
import {QqOutlined as QQ} from '@vicons/antd'
import {defineComponent, ref} from 'vue'
import {useLoadingBar} from 'naive-ui'

export default defineComponent({
  name: "Main",
  components: {
    WeChat, QQ
  },
  setup() {
    const loadingBar = useLoadingBar();
    const disabledRef = ref(true);
    return {
      disabled: disabledRef,
      handleStart() {
        loadingBar.start();
        disabledRef.value = false;
      },
      handleFinish() {
        loadingBar.finish();
        disabledRef.value = true;
      },
      handleError() {
        disabledRef.value = true;
        loadingBar.error();
      }
    }
  },
  data() {
    return {
      desc: "大雪压青松,青松挺且直.",
      width: 0,
      qqUrl: "",
      showModal: false,
      dayHttp: "https://www.mxnzp.com/api/daily_word/recommend?count=1&app_id=pl1orpnynmufreti&app_secret=MC9pdWNBbnVzS1FsL2orZEtHWERRZz09",
    }
  },
  methods: {
    weChat() {
      this.showModal = true;
    },
    qq() {
      //mqqwpa://im/chat？chat_type=wpa&uin=123456789&version=1&src_type=web&web_src=oicqzone.com
      //http://wpa.qq.com/msgrd?v=3&uin=408509165&site=qq&menu=yes
      window.open(this.qqUrl)
    },
  },
  watch: {
    width() {

    },
  },
  created() {
    this.handleStart();
    let self = this;
    let getWidth = () => {
      self.width = document.documentElement.clientWidth || document.body.clientWidth;
    }
    window.addEventListener("resize", getWidth);
    getWidth();
    this.qqUrl = "http://wpa.qq.com/msgrd?v=3&uin=408509165&site=qq&menu=yes";
    if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent) || /(Android)/i.test(navigator.userAgent)) {
      this.qqUrl = "mqqwpa://im/chat？chat_type=wpa&uin=123456789&version=1&src_type=web&web_src=oicqzone.com";
    }
    this.$axios.get(this.dayHttp).then(res => {
      if (res) {
        if (res.status === 200 && res.data.data.length) {
          this.desc = res.data.data[0].content;
        }
      }
    })
  }
});
</script>

<style scoped lang="less">
#main {
  height: 100vh;
  width: 100vw;
}

.fullScreenVideo {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  object-fit: fill;
  z-index: -1;
}

#main-container {
  position: relative;
  top: calc(50% - 100px);
  text-align: center;
  max-width: 768px;
  z-index: 100;
}

#headIcon {
  width: 12.5rem;
  height: 12.5rem;
  border-radius: 50%;
  border: 3px solid rgba(255, 255, 255, 0.3);
  position: relative;
  user-select: none;
}

#desc {
  background-color: rgba(0, 0, 0, 0.66);
  color: beige;
  //line-height: 3.75rem;
  text-align: center;
  width: 70%;
  margin-top: 1rem;
  user-select: none;
  border-radius: 4px;
}

.desc-content {
  line-height: 3.75rem;
  font-size: 1rem;
  font-weight: 400;
  font-family: "SimHei", serif;
}

#link-me {
  height: 3rem;
  line-height: 3rem;
  text-align: center;
  width: 60%;
  user-select: none;
  font-size: 2rem;
  margin-top: .5rem;

  & > button {
    margin-left: 4px;
    background-color: white;
    opacity: .8;
  }
}

.wechatpng {
  width: 100%;
  height: 32.5rem;
}

@media screen and (max-width: 768px) {
  .fullScreenVideo {
    height: 0;
  }

  .g-bg {
    height: 100vh;
  }
}

@media screen and (min-width: 768px) {
  .fullScreenVideo {
    height: 0;
  }

  .g-bg {
    height: 100vh;
  }
}

@media screen and (min-width: 992px) {
  .fullScreenVideo {
    height: 0;
  }

  .g-bg {
    height: 100vh;
  }
}

@media screen and (min-width: 1200px) {
  .fullScreenVideo {
    height: 100%;
  }

  .g-bg {
    height: 0;
  }

}

.g-bg {
  position: absolute;
  width: 100vw;
  overflow: hidden;

  & > div {
    position: absolute;
    opacity: .5;
  }

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    backdrop-filter: blur(100px);
    z-index: 1;
  }
}

.g-polygon-1 {
  bottom: 100px;
  left: 70%;
  transform: translate(-50%, 0);
  width: 714px;
  height: 390px;
  background: linear-gradient(#ffee55, #fdee99);
  clip-path: polygon(2% 2%, 130% 0, 180% 40%, 70% 100%, 20% 90%);
}

.g-polygon-2 {
  bottom: 0px;
  left: 30%;
  transform: translate(-50%, 0);
  width: 1000px;
  height: 450px;
  background: linear-gradient(-36deg, #E950D1, #f980D9);
  clip-path: polygon(10% 0, 100% 70%, 100% 100%, 20% 90%);
}

.g-polygon-3 {
  top: 0;
  left: 40%;
  transform: translate(-50%, 0);
  width: 1000px;
  height: 450px;
  background: rgba(87, 80, 233);
  clip-path: polygon(10% 0, 100% 0, 100% 100%, 20% 90%);
}

.color-change-2x {
  -webkit-animation: color-change-2x 4s linear infinite alternate both;
  animation: color-change-2x 4s linear infinite alternate both
}

@-webkit-keyframes color-change-2x {
  0% {
    background: #a8edea
  }
  100% {
    background: #fed6e3
  }
}

@keyframes color-change-2x {
  0% {
    background: #a8edea
  }
  100% {
    background: #fed6e3
  }
}

.color-change-3x {
  -webkit-animation: color-change-3x 6s linear infinite alternate both;
  animation: color-change-3x 6s linear infinite alternate both
}

@-webkit-keyframes color-change-3x {
  0% {
    background: #acb6e5
  }
  100% {
    background: #86fde8
  }
}

@keyframes color-change-3x {
  0% {
    background: #acb6e5
  }
  100% {
    background: #86fde8
  }
}

.color-change-1x {
  -webkit-animation: color-change-1x 2s linear infinite alternate both;
  animation: color-change-1x 2s linear infinite alternate both
}

@-webkit-keyframes color-change-1x {
  0% {
    background: #fc5185
  }
  100% {
    background: #3fc1c9
  }
}

@keyframes color-change-1x {
  0% {
    background: #fc5185
  }
  100% {
    background: #3fc1c9
  }
}

</style>