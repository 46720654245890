<template>
  <div id="beian">
    <a target="_blank" href="https://beian.miit.gov.cn/"
       style="display:inline-block;text-decoration:none;height:20px;line-height:20px;"><img
        src="../assets/备案图标.png"
        style="float:left;"/>
<!--      <p style="float:left;height:20px;line-height:20px;margin: 0px 0px 0px 5px; color:#939393;">蜀ICP备2021002870号-1</p>-->
      <p style="float:left;height:20px;line-height:20px;margin: 0px 0px 0px 5px; color:#939393;">蜀ICP备2021002870号-2</p>
    </a>
  </div>
</template>

<script>
export default {
  name: "Foot"
}
</script>

<style scoped lang="less">

#beian {
  width: 100%;
  height: 28px;
  position: absolute;
  bottom: 0;
  z-index: 100;
  line-height: 28px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
  align-items: center;
}

</style>